import React from "react";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import Button from "../form/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";

const useStyle = makeStyles({
  sendReset: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    textAlign: "center",
    display: "block",
    width: "55%",
  },
  textField: {
    width: "100%",
  },
  forgotButton: {
    float: "left",
    padding: "1% 3%",
    backgroundColor: "unset",
  },
});

interface ForgotPasswordInterface {
  sendPasswordEmail: () => void;
  handleChange: (event: any) => void;
  handleClose: () => void;
  handleOpen: () => void;
  openState: boolean;
  sentState: boolean;
}

const ForgotPassword = ({
  sendPasswordEmail,
  handleChange,
  handleClose,
  handleOpen,
  openState,
  sentState,
}: ForgotPasswordInterface) => {
  const classes = useStyle();
  return (
    <div>
      <Button
        type="button"
        variant="text"
        onClick={handleOpen}
        className={classNames("small", "btn-link", classes.forgotButton)}
        capitalizeLabel
      >
        Forgot Password?
      </Button>
      <Dialog title="Forgot Password" open={openState} onClose={handleClose}>
        <DialogContent>
          {sentState ? (
            <div>
              <DialogTitle>Forgot Password Sent</DialogTitle>
              <p>Email sent! Check your inbox and create your new password</p>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  {" "}
                  Close{" "}
                </Button>
              </DialogActions>
            </div>
          ) : (
            <form onSubmit={(e) => e.preventDefault()}>
              <Typography variant="h4">Forgot Password</Typography>
              <br />
              <DialogContentText>
                Enter the email you used to sign up for RoadBotics here and we
                will send you an email to reset your password
              </DialogContentText>

              <TextField
                variant="outlined"
                label="Email"
                onChange={handleChange}
                className={classes.textField}
              />

              <Button
                variant="contained"
                className={classes.sendReset}
                onClick={sendPasswordEmail}
                color="primary"
                type="submit"
              >
                Send Reset Email
              </Button>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ForgotPassword;
