import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/database";
import "@firebase/firestore";
import "@firebase/analytics";
import { FirebaseAnalytics } from "@firebase/analytics-types";

// Production
const config = {
  // from the API project
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

firebase.initializeApp(config);

let analytics: FirebaseAnalytics;
if (
  process.env.REACT_APP_ACCOUNTS_APP_ID &&
  process.env.REACT_APP_ACCOUNTS_MEASUREMENT_ID
) {
  try {
    const accountsFirebase = firebase.initializeApp(
      {
        apiKey: process.env.REACT_APP_ACCOUNTS_API_KEY,
        authDomain: process.env.REACT_APP_ACCOUNTS_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_ACCOUNTS_DATABASE_URL,
        projectId: process.env.REACT_APP_ACCOUNTS_PROJECT_ID,
        storageBucket: process.env.REACT_APP_ACCOUNTS_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_ACCOUNTS_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_ACCOUNTS_APP_ID,
        measurementId: process.env.REACT_APP_ACCOUNTS_MEASUREMENT_ID,
      },
      "Secondary"
    );
    analytics = accountsFirebase.analytics();
  } catch (e) {
    console.log("Unable to initialize FB app for analytics");
    console.log(e);
  }
}

export const trackEvent = (
  eventType: string,
  action: string,
  userUId: string | undefined
) => {
  // only track if env variables are set on production
  if (analytics) {
    // need a ts-ignore here because Google has a predefined set of events it allows
    // we want custom events, so it is yelling at us
    // @ts-ignore
    analytics.logEvent(eventType, {
      action,
      userUId,
    });
  }
};

export default firebase;
