import RBAPI from "roadbotics-api-wrapper";
import firebase from "../config/firebase";

const ROADWAY_API_BASE = process.env.REACT_APP_ROADWAY_API_BASE || "";

const roadBoticsAPI = new RBAPI(
  ROADWAY_API_BASE,
  `${process.env.REACT_APP_ACCOUNTS_DOMAIN}/login`,
  firebase
);

export default roadBoticsAPI;
