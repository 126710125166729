import { useEffect } from "react";

const useMount = (onMount: Function): void => {
  useEffect(() => {
    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useMount;
