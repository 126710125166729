import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RB_YELLOW } from "../../constants/colors";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
  },
  spinner: {
    color: RB_YELLOW,
    zIndex: -1,
    opacity: ".7",
  },
});

const LoadingSpinner = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={170} className={classes.spinner} />
    </div>
  );
};

export default LoadingSpinner;
