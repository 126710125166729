import React, { useState } from "react";
import LoginForm from "./Login";
import { validateEmail } from "../../utils/validateEmail";
import useMount from "../../hooks/useMount";
import { withRouter } from "react-router-dom";
import { signInUserAndRedirect } from "../../utils/signInUserAndRedirect";

const LoginContainer = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const update = (e: any) => {
    const name = e.target.name;
    if (name === "email") {
      setEmail(e.target.value);
    }

    if (name === "password") {
      setPassword(e.target.value);
    }
  };

  const signIn = (email: string, password: string) => {
    try {
      const imageLoggerSuccessIndexURL = document.referrer.search(
        "agile-mapper-success"
      );
      const freeTrialSuccessIndexURL = document.referrer.search(
        "free-trial-success"
      );
      if (imageLoggerSuccessIndexURL !== -1) {
        signInUserAndRedirect(
          email,
          password,
          document.referrer.substring(0, imageLoggerSuccessIndexURL).replace("roadway", "agilemapper")
        );
      } else if (freeTrialSuccessIndexURL !== -1) {
        signInUserAndRedirect(
          email,
          password,
          document.referrer.substring(0, freeTrialSuccessIndexURL)
        );
      } else {
        signInUserAndRedirect(email, password, document.referrer);
      }
    } catch (e) {
      if (e.code !== "auth/too-many-requests") {
        // eslint-disable-next-line no-alert
        alert(e.message);
      } else {
        handleDialogOpen();
      }
    }
  };

  const validate = (): boolean => {
    let isError = false;
    if (!email) {
      isError = true;
      setEmailError("Email is a required field");
    }
    if (!validateEmail(email)) {
      isError = true;
      setEmailError("Email format is invalid");
    }
    if (!password) {
      isError = true;
      setPasswordError("Password is a required field");
    }
    if (password.length < 8) {
      isError = true;
      setPasswordError("Password must be at least 8 characters long");
    }
    return isError;
  };

  const resetErrors = (): void => {
    setEmailError("");
    setPasswordError("");
  };

  const loginUser = (e: React.FormEvent<HTMLFormElement>) => {
    resetErrors();
    if (e) e.preventDefault();

    const error = validate();
    if (!error) {
      signIn(email, password);
    }
  };

  useMount(() => {
    if (process.env.REACT_APP_ISDEMO === "1") {
      console.log("IS DEMO");
      signIn("demo@roadbotics.com", "roadbotics");
    }
  });

  return (
    <LoginForm
      loginUser={loginUser}
      open={isDialogOpen}
      handleDialogClose={handleDialogClose}
      emailError={emailError}
      email={email}
      update={update}
      passwordError={passwordError}
      password={password}
    />
  );
};

export default withRouter(LoginContainer);
