import React, { useState } from "react";
import LoginContainer from "../Login/LoginContainer";
import useMount from "../../hooks/useMount";
import RBAPI from "../../api/RoadwayAPI";
import AlreadyLoggedInContainer from "../Login/AlreadyLoggedInContainer";
import BackgroundContainer from "../Login/BackgroundContainer";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const Accounts = () => {
  const [isValidSessionToken, setIsValidSessionToken] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

  useMount(async () => {
    setShowLoadingSpinner(true);
    try {
      const resp = await RBAPI.validateSessionToken();
      if (resp.status === 200) {
        setIsValidSessionToken(true);
        const roadwayURL = process.env.REACT_APP_ROADWAY_URL || ""
        window.location.replace(roadwayURL);
      }
    } catch (e) {
      // go to the authentication page
      console.log(e.response.data.accountsRedirectURL);
      // do not need the following line for the accounts page as we are already on accounts
      // but will need it on other sites
      // window.location.replace(e.response.data.accountsRedirectURL);
    }
    setShowLoadingSpinner(false);
  });

  return (
    <div>
      <BackgroundContainer>
        <div>
          {showLoadingSpinner ? (
            <LoadingSpinner />
          ) : !isValidSessionToken ? (
            <LoginContainer />
          ) : (
            <AlreadyLoggedInContainer />
          )}
        </div>
      </BackgroundContainer>
    </div>
  );
};

export default Accounts;
