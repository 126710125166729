import React, { useState } from "react";
import AlreadyLoggedIn from "./AlreadyLoggedIn";

const AlreadyLoggedInContainer = (): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <AlreadyLoggedIn
      open={isDialogOpen}
      handleDialogClose={handleDialogClose}
    />
  );
};

export default AlreadyLoggedInContainer;
