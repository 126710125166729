import React, { useState, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import EmptyPage from "../EmptyPage/EmptyPage";
import firebase from "../../config/firebase";
import RBAPI from "../../api/RoadwayAPI";
import useMount from "../../hooks/useMount";
import useMountState from "../../hooks/useMountState";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { ROUTE_PASSWORD_SET } from "../../constants/routes";

interface SignUpInterface {
  match: {
    params: {
      token: string;
    };
  };
  history: { push: (arg0: string) => void };
}

const SignUpContainer = ({ match, history }: SignUpInterface) => {
  const isMounted = useMountState();

  const [isValidatingInvite, setIsValidatingInvite] = useState(true);
  const [isValidInvite, setIsValidInvite] = useState(true);

  const signIn = async () => {
    // logout of the session to clear the cookies before signing in as new user
    // this prevents an issue with inviting someone on the same browser
    // mainly impacts roadbotics employees
    await RBAPI.logoutSession();

    try {
      const { token } = await RBAPI.validateInvite(match.params.token);
      // @ts-ignore
      await firebase.auth().signInWithCustomToken(token);
    } catch (err) {
      if (isMounted()) {
        setIsValidInvite(false);
      }
    } finally {
      if (isMounted()) {
        setIsValidatingInvite(false);
      }
    }
  };

  // Decode the token and set the user;
  useMount(signIn);

  const renderValidatingInvite = () => (
    <EmptyPage includeRoadwayLink={false} history={history}>
      <CircularProgress />
      <Typography variant="subtitle1" gutterBottom>
        Validating invite
      </Typography>
    </EmptyPage>
  );

  const renderInvalidInvite = () => {
    // @ts-ignore
    if (!firebase.auth().currentUser) {
      alert(
        "This invite is no longer valid. If you cannot login, please reach out to RoadBotics"
      );
    }
    return <Redirect to="/" />;
  };

  const renderValidatedView = () =>
    isValidInvite ? (
      <Fragment>
        <Redirect to={ROUTE_PASSWORD_SET} />
      </Fragment>
    ) : (
        renderInvalidInvite()
      );

  return isValidatingInvite ? renderValidatingInvite() : renderValidatedView();
};

export default withRouter(SignUpContainer);
