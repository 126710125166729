import firebase, { trackEvent } from "../config/firebase";
import getCsrfCookie from "./getCookie";
import RBAPI from "../api/RoadwayAPI";

export const signInUserAndRedirect = async (
  email: string,
  password: string,
  redirect: string
) => {
  // As httpOnly cookies are to be used, do not persist any state client side.
  // @ts-ignore
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

  // @ts-ignore
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((resp) => {
      // const { email, uid: userUId } = resp.user;
      return resp.user?.getIdToken().then(async (idToken) => {
        // Session login endpoint is queried and the session cookie is set.
        // CSRF protection should be taken into account.
        // ...
        const csrfToken = getCsrfCookie(idToken);
        if (csrfToken) {
          await RBAPI.postIdTokenToSessionLogin(idToken, csrfToken);
        } else {
          alert("session could not be verified");
        }

        trackEvent("mouse-click", "login-user", resp.user?.uid);

        return;
      });
    })
    .then(() => {
      // A page redirect would suffice as the persistence is set to NONE.
      // @ts-ignore
      return firebase.auth().signOut();
    })
    .then(() => {
      if (redirect) {
        window.location.replace(redirect);
      } else {
        window.location.replace(
          process.env.REACT_APP_ROADWAY_URL + "/dashboard"
        );
      }
    })
    .catch((error) => {
      alert(error.message);
    });
};
