import firebase from "../../config/firebase";
import React, { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import { validateEmail } from "../../utils/validateEmail";
import RBAPI from "../../api/RoadwayAPI";

const ForgotPasswordContainer = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [value, setValue] = useState("");

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    setIsSent(false);
  };

  const handleChange = (event: any): void => {
    setValue(event.target.value);
  };

  const sendPasswordEmail = async () => {
    if (!validateEmail(value)) {
      // eslint-disable-next-line no-alert
      alert("Please enter a valid email address");
      return;
    }
    try {
      await RBAPI.checkIfUserHasPasswordByEmail(value);
      // @ts-ignore
      firebase.auth().sendPasswordResetEmail(value);
      setIsSent(true);
    } catch {
      alert(
        "User does not have a password set yet.  Please check your email to follow an invite"
      );
      return;
    }
  };

  return (
    <ForgotPassword
      sendPasswordEmail={sendPasswordEmail}
      handleChange={handleChange}
      handleClose={handleClose}
      handleOpen={handleOpen}
      openState={isOpen}
      sentState={isSent}
    />
  );
};

export default ForgotPasswordContainer;
