import React from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "../form/Button";
import { Link } from "react-router-dom";
import { ROUTE_LOGIN } from "../../constants/routes";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  textField: {
    margin: "1%",
    width: "50%",
    textAlign: "center",
  },
  card: {
    marginTop: "5%",
  },
  actions: {
    float: "right",
  },
  container: {
    marginTop: "80px",
  },
});

interface PasswordResetInterface {
  email: string;
  isResetFinished: boolean;
  isExpiredLink: boolean;
  password: string;
  confirmPassword: string;
  setPassword: (arg0: string) => void;
  setConfirmPassword: (arg0: string) => void;
  submit: () => void;
}

const PasswordReset = ({
  email,
  isResetFinished,
  isExpiredLink,
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  submit,
}: PasswordResetInterface) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Card className={classes.card}>
            <form
              style={{ textAlign: "center" }}
              onSubmit={(e) => e.preventDefault()}
            >
              <CardHeader
                title={
                  <Typography variant="h4">RoadWay Password Reset</Typography>
                }
              />
              <Divider />
              {isResetFinished ? (
                <div>
                  <CardContent>
                    <Typography>
                      Your password has been reset. Click below to go back to
                      the login.
                    </Typography>
                  </CardContent>
                  <br />
                  <CardActions className={classes.actions}>
                    <Link to={`${ROUTE_LOGIN}`}>
                      <Button variant="contained" color="primary" type="submit">
                        <Typography>Back to login</Typography>
                      </Button>
                    </Link>
                  </CardActions>
                </div>
              ) : isExpiredLink ? (
                <div>
                  <CardContent>
                    <Typography>
                      The email link has expired. Please press forgot password
                      again.
                    </Typography>
                  </CardContent>
                  <br />
                  <CardActions className={classes.actions}>
                    <Link to={`${ROUTE_LOGIN}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        <Typography>Back to login</Typography>
                      </Button>
                    </Link>
                  </CardActions>
                </div>
              ) : (
                <div>
                  <CardContent>
                    <Typography variant="body1" gutterBottom>
                      Please fill out the following information to reset
                      password for {email}:
                    </Typography>
                    <br />
                    <div>
                      <TextField
                        className={classes.textField}
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        inputProps={{ type: "password" }}
                      />
                    </div>
                    <br />
                    <div>
                      <TextField
                        className={classes.textField}
                        label="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        inputProps={{ type: "password" }}
                      />
                    </div>
                  </CardContent>
                  <br />
                  <CardActions className={classes.actions}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submit}
                      type="submit"
                    >
                      <Typography>Reset your password</Typography>
                    </Button>
                  </CardActions>
                </div>
              )}
            </form>
          </Card>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </div>
  );
};

export default PasswordReset;
