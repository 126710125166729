import React, { Fragment } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "../form/Button";

const useStyles = makeStyles({
  wrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "500px",
  },
  content: {
    textAlign: "center",
  },
});

interface EmptyPageInterface {
  children: object;
  history: {
    push: (arg0: string) => void;
  };
  includeRoadwayLink: boolean;
}

const EmptyPage = ({
  history,
  children,
  includeRoadwayLink = true,
}: EmptyPageInterface) => {
  const classes = useStyles();
  return (
    <Fragment>
      <main className={classes.wrapper}>
        <article className={classes.content}>
          {children}
          {history && includeRoadwayLink && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/")}
            >
              Go to RoadWay
            </Button>
          )}
        </article>
      </main>
    </Fragment>
  );
};

export default EmptyPage;
