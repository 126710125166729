import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import loginBackground from "../../images/loginbackground.png";
import notfound from "../../images/404box-notext.png";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { RB_YELLOW } from "../../constants/colors";

const useStyles = makeStyles({
  divWhole: {
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    zIndex: 100,
  },
  gloss: {
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(200,200,200,.8)",
  },
  image: {
    maxWidth: "100%",
  },
  imageContainer: {
    position: "absolute",
    textAlign: "center",
    left: "47%",
    top: "40%",
    transform: "translate(-50%, -50%)",
    width: "600px",
  },
  errorTitle: {
    position: "absolute",
    top: "9%",
    left: "48%",
    fontSize: "80px",
    fontWeight: "bold",
    color: RB_YELLOW,
  },
  errorText: {
    position: "absolute",
    top: "55%",
    left: "60%",
    fontSize: "16px",
    transform: "translate(-50%, -50%)",
  },
  button: {
    position: "absolute",
    top: "70%",
    left: "53.5%",
    textTransform: "none",
    backgroundColor: RB_YELLOW,
    marginTop: "1%",
  },
  buttonText: {
    color: "#000",
    fontSize: "16px",
  },
});

interface PageNotFoundInterface {
  history: { push: (arg0: string) => void };
}

const PageNotFound = ({ history }: PageNotFoundInterface) => {
  const classes = useStyles();
  return (
    <div className={classes.divWhole}>
      <div className={classes.gloss}>
        <Grid container>
          <Grid item xs={5} />
          <Grid item xs={1}>
            <div>
              <div className={classes.imageContainer}>
                <img src={notfound} alt="notfound" className={classes.image} />
                <Typography className={classes.errorTitle}>404</Typography>
                <Typography className={classes.errorText}>
                  {`Sorry, the page you're looking for cannot be found. Click
                  below to make a u-turn.`}
                </Typography>
                <Button
                  className={classes.button}
                  onClick={() => history.push("/")}
                >
                  <Typography className={classes.buttonText}>
                    Go Back
                  </Typography>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={5} />
        </Grid>
      </div>
    </div>
  );
};

PageNotFound.propTypes = {
  history: PropTypes.object.isRequired,
};

export default PageNotFound;
