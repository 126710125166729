import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import firebase from "../../config/firebase";
import RBAPI from "../../api/RoadwayAPI";
import PasswordSet from "./PasswordSet";
import UsersStatus from "../../constants/userStatus";
import { signInUserAndRedirect } from "../../utils/signInUserAndRedirect";
import { useLocation } from "react-router-dom";
import { INVITE_FORM_ID, PORTAL_ID } from "../../constants/hubspot";
import { getCookie } from "../../utils/getCookie";

interface PasswordSetContainerInterface {
  history: { push: (arg0: string) => void };
}

const PasswordSetContainer = ({ history }: PasswordSetContainerInterface) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("")
  const [uid, setUid] = useState("")
  const location = useLocation();

  const updatePassword = async () => {
    // @ts-ignore
    await firebase.auth().currentUser.updatePassword(password);
  };

  const captureHubspot = async (userId: string, userEmail: string) => {
    // capture on hubspot
    try {
      const memberships = await RBAPI.fetchMembershipsByUsers(userId);
      const organizationId = memberships[0]?.organizationId;
      let organizationName = "unknown";
      if (organizationId) {
        const organization = await RBAPI.getOrganization(organizationId);
        organizationName = organization.name;
      }
      await RBAPI.submitHubSpotForm(
        firstName,
        lastName,
        userEmail,
        process.env.REACT_APP_ROADWAY_URL + "/" + location.pathname,
        getCookie("hubspotutk"),
        PORTAL_ID,
        INVITE_FORM_ID,
        organizationName
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const setEmailAndPassword = async () => {
      let uid = ""
      let email = ""
      try {
        // @ts-ignore
        uid = firebase.auth().currentUser?.uid;
        // @ts-ignore
        email = firebase.auth().currentUser.email || "";
      } catch {
        const data = await RBAPI.validateSessionToken();
        uid = data.data.data.decodedClaims.uid
        email = data.data.data.decodedClaims.email
      }
      setEmail(email);
      setUid(uid);
    }
    setEmailAndPassword()
  }, [])

  const submitUser = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    // @ts-ignore
    if (firstName && lastName) {
      if (password && password === confirmPassword) {
        if (password.length < 8) {
          alert("Password length must be at least 8 characters.");
          return;
        }
        const editedUser = {
          // @ts-ignore
          id: uid,
          firstName,
          lastName,
          status: UsersStatus.ACTIVE,
        };

        try {
          await updatePassword();
          await RBAPI.updateUser(editedUser);

          await captureHubspot(editedUser.id, email);

          await signInUserAndRedirect(
            // @ts-ignore
            email,
            password,
            process.env.REACT_APP_ROADWAY_URL || ""
          );
        } catch (err) {
          console.error(err);
          const errorMessages = [
            "Please refresh your page or open the link from your email again.",
            "If you are still receiving an error, contact your administrator to resend the invite.",
          ];
          // eslint-disable-next-line no-alert
          alert(errorMessages.join(" "));
          // logoutUser();
          // @ts-ignore
          await firebase.auth().signOut();
          history.push("/");
        }
      } else {
        alert("Passwords do not match");
      }
    } else {
      alert("First Name and Last Name is required");
    }
  };

  return (
    <PasswordSet
      firstName={firstName}
      lastName={lastName}
      password={password}
      confirmPassword={confirmPassword}
      setFirstName={setFirstName}
      setLastName={setLastName}
      setPassword={setPassword}
      setConfirmPassword={setConfirmPassword}
      submit={submitUser}
    />
  );
};

export default withRouter(PasswordSetContainer);
