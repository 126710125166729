import React from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "../form/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  textField: {
    margin: "1%",
    width: "50%",
    textAlign: "center",
  },
  card: {
    marginTop: "5%",
  },
  actions: {
    float: "right",
  },
  container: {
    marginTop: "80px",
  },
});

interface PasswordSetInterface {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  setFirstName: (arg0: string) => void;
  setLastName: (arg0: string) => void;
  setPassword: (arg0: string) => void;
  setConfirmPassword: (arg0: string) => void;
  submit: (e: any) => Promise<any>;
  isNewUser?: boolean;
}

const PasswordSet = ({
  firstName,
  lastName,
  password,
  confirmPassword,
  setFirstName,
  setLastName,
  setPassword,
  setConfirmPassword,
  submit,
  isNewUser = true,
}: PasswordSetInterface) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Card className={classes.card}>
            <form style={{ textAlign: "center" }} onSubmit={submit}>
              <CardHeader
                title={<Typography variant="h4">Welcome to RoadWay</Typography>}
              />
              <Divider />
              <CardContent>
                <Typography variant="body1" gutterBottom>
                  Please fill out the following information:
                </Typography>
                <div>
                  <TextField
                    className={classes.textField}
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <br />
                <div>
                  <TextField
                    className={classes.textField}
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <br />
                {isNewUser ? (
                  <div>
                    <TextField
                      className={classes.textField}
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      inputProps={{ type: "password" }}
                    />
                  </div>
                ) : null}
                <br />
                {isNewUser ? (
                  <div>
                    <TextField
                      className={classes.textField}
                      label="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      inputProps={{ type: "password" }}
                    />
                  </div>
                ) : null}
              </CardContent>
              <br />
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  type="submit"
                >
                  <Typography>Create your profile</Typography>
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </div>
  );
};

export default PasswordSet;
