import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import loginBackground from "../../images/loginbackground.png";
import logoWithText from "../../images/logos/RoadboticsLogoWithText.png";

const useStyles = makeStyles({
  background: {
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    zIndex: 100,
  },
  card: {
    width: "35%",
    margin: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    border: "1px solid #344560",
    borderRadius: "5px",
    transform: "translate(-50%, -50%)",
  },
  logoWithTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoWithText: {
    width: "230px",
    paddingTop: "50px",
    paddingBottom: "10px",
  },
});

interface BackgroundContainerInterface {
  children: JSX.Element;
}

const BackgroundContainer = ({
  children,
}: BackgroundContainerInterface): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.background}>
      <Card raised={true} className={classes.card}>
        <div className={classes.logoWithTextContainer}>
          <img
            alt="logoWithText"
            src={logoWithText}
            className={classes.logoWithText}
          />
        </div>
        <CardContent>{children}</CardContent>
      </Card>
    </div>
  );
};

export default BackgroundContainer;
