import { useEffect, useRef, useCallback } from "react";

const useMountState = () => {
  const isMounted = useRef(false);
  const getMountState = useCallback(() => isMounted.current, []);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return getMountState;
};

export default useMountState;
