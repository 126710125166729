import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Accounts from "../Accounts/Accounts";
import SignUpContainer from "../SignUp/SignUpContainer";
import PageNotFound from "../PageNotFound/PageNotFound";
import PasswordSetContainer from "../PasswordSet/PasswordSetContainer";
import PasswordResetContainer from "../PasswordReset/PasswordResetContainer";
import {
  ROUTE_SIGN_UP,
  ROUTE_NOT_FOUND,
  ROUTE_PASSWORD_SET,
  ROUTE_LOGIN,
  ROUTE_RESET_PASSWORD,
} from "../../constants/routes";

const Router = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={ROUTE_LOGIN} />} />
      <Route path={ROUTE_LOGIN} component={Accounts} />
      <Route path={`${ROUTE_SIGN_UP}/:token`} component={SignUpContainer} />
      <Route path={`${ROUTE_PASSWORD_SET}`} component={PasswordSetContainer} />
      <Route path={ROUTE_NOT_FOUND} component={PageNotFound} />
      <Route path={ROUTE_RESET_PASSWORD} component={PasswordResetContainer} />
      <Redirect to={ROUTE_NOT_FOUND} />
    </Switch>
  );
};

export default Router;
