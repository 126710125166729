import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ForgotPasswordContainer from "../ForgotPassword/ForgotPasswordContainer";
import Button from "../form/Button";
import { FAQ } from "../../constants/links";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";

const useStyles = makeStyles({
  button: {
    float: "right",
    padding: "1% 17%",
  },
  textField: {
    width: "95%",
    marginTop: "5%",
  },
  header: {
    marginBottom: "2%",
    fontSize: "45px",
    fontWeight: 900,
    fontFamily: "'Open Sans', sans-serif",
    letterSpacing: "1px",
    textShadow: "2px 0",
  },
  buttonsDiv: {
    marginRight: "2.5%",
  },
  formGroup: {
    display: "flex",
    justifyContent: "center",
  },
});

interface LoginFormInterface {
  loginUser: (event: React.FormEvent<HTMLFormElement>) => void;
  emailError: string;
  email: string;
  update: Function;
  passwordError: string;
  password: string;
  open: boolean;
  handleDialogClose: (
    e: object,
    reason: "backdropClick" | "escapeKeyDown"
  ) => void;
}

const LoginForm = ({
  loginUser,
  emailError,
  email,
  update,
  passwordError,
  password,
  open,
  handleDialogClose,
}: LoginFormInterface): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <form onSubmit={loginUser}>
        <Typography
          align="center"
          variant="h3"
          className={classNames("login-title", classes.header)}
        >
          Login to RoadBotics
        </Typography>
        <Typography align="center" variant="subtitle1">
          Please enter your email and password
        </Typography>
        <div className={classes.formGroup}>
          <TextField
            error={emailError !== ""}
            helperText={emailError}
            variant="outlined"
            id="emailInput"
            className={classNames("form-control", classes.textField)}
            name="email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(event): void => update(event)}
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            error={passwordError !== ""}
            helperText={passwordError}
            variant="outlined"
            id="passwordInput"
            className={classNames("form-control", classes.textField)}
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event): void => update(event)}
          />
        </div>
        <br />
        <div className={classes.buttonsDiv}>
          <ForgotPasswordContainer />
          <Button
            variant="contained"
            type="submit"
            className={classNames("login-btn", classes.button)}
            color="primary"
          >
            Log In
          </Button>
          <br />
        </div>
      </form>
      <br />
      <p style={{ textAlign: "center" }} className="support text-center">
        Need help? Contact our
        <a href={FAQ} style={{ color: "blue" }}>
          &nbsp;support team
        </a>
        .
      </p>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogContent>
          There have been too many unsuccessful logins. Please try again in a
          few minutes. If you do not remember your password, try resetting it by
          clicking on &quot;Forgot Password&quot;.
          <br />
          <br />
          If you need support, please feel free to
          <a href="mailto:support@roadbotics.com"> email us </a>
        </DialogContent>

        <DialogActions>
          <ForgotPasswordContainer />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginForm;
