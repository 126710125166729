import jwt from "jsonwebtoken";

export const getCookie = function(cookieName: string): string {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const getCsrfCookie = (idToken: string) => {
  const csrfKey = process.env.REACT_APP_CSRF_SECRET_KEY;
  if (csrfKey) {
    const csrfToken = jwt.sign({ idToken }, csrfKey);
    const domain = process.env.REACT_APP_ROADWAY_API_BASE?.split(".")
      .slice(1)
      .join(".");

    if (domain) {
      document.cookie = `csrfToken_.${domain}=${csrfToken};domain=.${domain};path=/`;
    } else {
      document.cookie = `csrfToken_=${csrfToken};domain=localhost;path=/`;
    }
    return csrfToken;
  } else {
    return null;
  }
};

export default getCsrfCookie;
