import React, { useState, Fragment } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { parse } from "query-string";
import firebase from "../../config/firebase";
import { ROUTE_LOGIN } from "../../constants/routes";
import PasswordReset from "./PasswordReset";

interface PasswordResetContainerInterface {
  location: {
    search: string;
  };
}

const PasswordResetContainer = ({
  location,
}: PasswordResetContainerInterface) => {
  const parseQuery = () => {
    return parse(location.search);
  };

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [queryParams] = useState(parseQuery);
  const [isResetFinished, setIsResetFinished] = useState(false);
  const [isExpiredLink, setIsExpiredLink] = useState(false);

  const checkValidPasswordReset = async () => {
    const actionCode = queryParams.oobCode || "";
    const mode = queryParams.mode;
    if (typeof actionCode === "string") {
      if (mode === "resetPassword" && !isResetFinished) {
        // @ts-ignore
        const auth = firebase.auth();
        await auth
          .verifyPasswordResetCode(actionCode)
          .then((email: string) => {
            setEmail(email);
            return true;
          })
          .catch((error: string) => {
            console.error(error);
            setIsExpiredLink(true);
            return false;
          });
      } else if (!isExpiredLink) {
        setIsExpiredLink(true);
      }
    }
  };

  const updatePassword = async () => {
    // @ts-ignore
    const auth = firebase.auth();
    const actionCode = queryParams.oobCode || "";
    if (typeof actionCode === "string") {
      await auth
        .confirmPasswordReset(actionCode, password)
        .then(() => {
          setIsResetFinished(true);
        })
        .catch((error: string) => {
          console.error(error);
          alert("Code has expired or password is too weak");
        });
    }
  };

  return (
    <div>
      {checkValidPasswordReset() ? (
        <Fragment>
          <PasswordReset
            isResetFinished={isResetFinished}
            isExpiredLink={isExpiredLink}
            email={email}
            password={password}
            confirmPassword={confirmPassword}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            submit={updatePassword}
          />
        </Fragment>
      ) : (
        <Redirect
          to={{
            pathname: ROUTE_LOGIN,
            state: { from: location },
          }}
        />
      )}
    </div>
  );
};

export default withRouter(PasswordResetContainer);
