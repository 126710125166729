import React from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ForgotPasswordContainer from "../ForgotPassword/ForgotPasswordContainer";
import { FAQ } from "../../constants/links";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";

const useStyles = makeStyles({
  header: {
    marginBottom: "2%",
    fontSize: "45px",
    fontWeight: 900,
    fontFamily: "'Open Sans', sans-serif",
    letterSpacing: "1px",
    textShadow: "2px 0",
  },
});

interface AlreadyLoggedInInterface {
  open: boolean;
  handleDialogClose: (
    e: object,
    reason: "backdropClick" | "escapeKeyDown"
  ) => void;
}

const AlreadyLoggedIn = ({
  open,
  handleDialogClose,
}: AlreadyLoggedInInterface): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <Typography
        align="center"
        variant="h3"
        className={classNames("login-title", classes.header)}
      >
        Already logged in
      </Typography>
      <br />
      <p style={{ textAlign: "center" }} className="support text-center">
        Need help? Contact our
        <a href={FAQ} style={{ color: "blue" }}>
          &nbsp;support team
        </a>
        .
      </p>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogContent>
          There have been too many unsuccessful logins. Please try again in a
          few minutes. If you do not remember your password, try resetting it by
          clicking on &quot;Forgot Password&quot;.
          <br />
          <br />
          If you need support, please feel free to
          <a href="mailto:support@roadbotics.com"> email us </a>
        </DialogContent>

        <DialogActions>
          <ForgotPasswordContainer />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlreadyLoggedIn;
